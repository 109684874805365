import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration-line: none;
  &:link {
    color: var(--accent-orange);
  }
  &:visited {
    color: var(--accent-orange);
  }
  &:hover { 
    background-color: var(--off-dark);
    color: var(--alert-yellow);
  };
`

export default StyledLink