import styled from 'styled-components'

const Anchor = styled.a`
  text-decoration: none;
  &:link {
    color: var(--accent-orange);
  }
  &:visited {
    color: var(--accent-orange);
  }
  &:not([class*="linkicon"]):hover { 
    background-color: var(--off-white-inversion);
    color: var(--alert-yellow);
  };
`

export default Anchor