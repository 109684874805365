import React from "react"
import styled from "styled-components"
import StyledLink from "../components/link"
import Anchor from "../components/mdx/anchor"

import SEO from "../components/seo"
import Layout from "../components/global-layout"

const LandingTextContainer = styled.div`
  justify-content: left;
  font-size: 36px;
  font-weight: 700;
  width: 900px;
  margin-top: 1.5em;
  flex-grow: 1;
  & > p {
    color: var(--off-dark);
  };

  @media screen and (max-width: 1028px) {
    width: auto;
  }

  @media screen and (max-width: 728px) {
    font-size: 24px;
  }
`

// Landing
const IndexPage = () => (
  <Layout>
    <SEO title="home" />
    <LandingTextContainer>
      <p>
        I am a <StyledLink to="/about/day/">software engineer</StyledLink> by day,<br/>
        <StyledLink to="/about/night/">everything else</StyledLink> by night.
      </p>
      <p>
        This is where I <StyledLink to="/posts/">record my life</StyledLink><br/>
        as I try to make sense of{" "}
        <StyledLink to="/art/">art</StyledLink>,{" "}
        <StyledLink to="/music/">music</StyledLink>,{" "} 
        <StyledLink to="/dev/">software</StyledLink> <br/>and{" "}
        <StyledLink to="/etc/">anything else</StyledLink> that sparks my interest.
      </p>
      <p>
        This is what I'm up to right <StyledLink to="/now/">now</StyledLink>.
      </p>
      <p className="last">
        Visit my <Anchor href="https://cerebrarium.garden">cerebrarium</Anchor> for less polished thoughts.
      </p>
    </LandingTextContainer>
  </Layout>
)

export default IndexPage
